import React, { useEffect, useState } from "react";
import android from "../../../assets/images/android.png";
import apple from "../../../assets/images/ios-1.png";
import {
  Details,
  Header,
  LeftSide,
  MobileWrapper,
  Photo,
  RightSide,
  SocialMedia,
  SocialMediaImage,
  Wrapper,
} from "../MobileApp/MobileApp.styles";
import Container from "../../../bootstrap/Container";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { BookingBtn } from "../Journy/Journy.styles";
// import {
//   Details,
//   Header,
//   LeftSide,
//   MobileWrapper,
//   Photo,
//   RightSide,
//   SocialMedia,
//   SocialMediaImage,
//   Wrapper,
// } from "./MobileApp.styles.js";

const RentCar = () => {
  const [appData, setAppData] = useState(null);
  const { webSettingData, languageData } = useSelector(
    (state) => state.busLists
  );
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/app`)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          setAppData(result?.data[0]);
        }
      });
  }, []);

  return (
    <Wrapper>
      <Container>
        <MobileWrapper>
          <LeftSide>
            <Photo img={appData?.image} alt="Rent car" />
          </LeftSide>
          <RightSide>
            <Header>{appData?.title}</Header>
            <Details>{appData?.sub_title}</Details>
            <SocialMedia>
              <>
                <BookingBtn
                  to={"/rent-form"}
                  // onClick={() => handleBooking(trip)}
                  btnbgcolor={webSettingData?.buttoncolor}
                  btnbghvcolor={webSettingData?.buttoncolorhover}
                  btntextcolor={webSettingData?.buttontextcolor}
                >
                  {languageData?.book_now
                    ? languageData?.book_now[webSettingData?.language]
                    : "book_now"}
                </BookingBtn>
              </>
            </SocialMedia>
          </RightSide>
        </MobileWrapper>
      </Container>
    </Wrapper>
  );
};

export default RentCar;
