"use client";

import styled from "styled-components";
import SectionHeader from "../SectionHeader";
import { useDispatch, useSelector } from "react-redux";
import { BookingBtn, Location, Price } from "../Journy/Journy.styles";
import { useHistory } from "react-router-dom";
import { dateForm } from "../../../helpers";
import { useEffect, useState } from "react";
import { addBusName, SearchInfoStore } from "../../../redux/action/busAction";
import { Oval } from "react-loader-spinner";

const Container = styled.div`
  /* width: 100%; */
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  overflow-y: auto;
  background-color: #f8f8f8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 550px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media (max-width: 768px) {
    padding: 10px;

    height: 350px;
    margin-top: 20px;
    padding: 10px;
  }
`;

const Card = styled.article`
  width: 70%;
  margin: 0 auto 20px;
  border-radius: 8px;
  background-color: white;

  @media (max-width: 768px) {
    margin-top: 50px;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  border: 0.5px solid #a1a1a1;
  border-radius: 10px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Image = styled.img`
  width: 80%;
  /* padding: 10px; */
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;

  height: 250px;
  border-right: 1px solid black;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

const Content = styled.div`
  padding: 24px;
  width: 20%;
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent); */
  color: black;
  margin-top: auto;
  margin-left: auto;

  border-radius: 9px;

  @media (max-width: 768px) {
    padding: 16px;
    width: 100%;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 12px;
  }
`;

const Meta = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 16px;
  font-size: 14px;
  opacity: 0.9;

  @media (max-width: 768px) {
    gap: 12px;
    font-size: 12px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 12px;
    margin-left: auto;
  }
`;

const Button = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  background-color: #d11919;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;

  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;
const HeaderWrapper = styled.div`
  padding-top: 50px;
  width: 50%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export default function TripCards() {
  const { webSettingData, languageData } = useSelector(
    (state) => state.busLists
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [journyStartDate, setJournyStartDate] = useState(new window.Date());
  const [journyHeader, setJournyHeader] = useState([]);
  const [journyData, setJournyData] = useState([]);
  const [locationName, setLocationName] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getJourneyHeader();
    getJourneyContent();
    getLocation();

    return () => {
      setJournyHeader({});
      setJournyData({});
      setLocationName({});
    };
  }, []);

  const getJourneyHeader = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/journey`
      );
      const result = await response.json();

      if (result.status === "success") {
        setJournyHeader(result.data[0]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Journey error", error);
    }
  };

  const getJourneyContent = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/journey/trips`
      );
      const result = await response.json();

      if (result.status === "success") {
        setJournyData(result.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Trips error", error);
    }
  };

  const getLocation = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_MODULE_DOMAIN}/locations`
      );
      const result = await response.json();

      if (result.status === "success") {
        setLocationName(result?.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Location error", error);
    }
  };
  const showLatestTrip = (pickId, dropId) => {
    const pickLocaiton = locationName.find((item) => item.id === pickId);
    const dropLocaiton = locationName.find((item) => item.id === dropId);
    return `${pickLocaiton?.name} - ${dropLocaiton?.name}`;
  };
  const handleBooking = async (item) => {
    history.push("/search-again");
    const searchInfo = {
      pickLocation: item?.pick_location_id,
      dropLocation: item?.drop_location_id,
      journeydate: dateForm(journyStartDate),
      returnDate: "",
    };
    dispatch(SearchInfoStore(searchInfo));
    localStorage.removeItem("isTourism");
    localStorage.setItem("searchInfo", JSON.stringify(searchInfo));
    localStorage.setItem("singleTrip", JSON.stringify(item));
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Oval
            visible={true}
            height="50"
            width="50"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <HeaderWrapper>
            <SectionHeader
              header={journyHeader.title}
              subHeader={journyHeader.sub_title}
            />
          </HeaderWrapper>
          <Container>
            {journyData?.map((trip) => (
              <Card key={trip.id}>
                <ImageContainer>
                  <Image src={trip?.imglocation} alt={trip.title} />
                  <Content>
                    {showLatestTrip(
                      trip.pick_location_id,
                      trip.drop_location_id
                    )}

                    <ButtonWrapper>
                      <BookingBtn
                        //   to="/search-again"
                        onClick={() => handleBooking(trip)}
                        btnbgcolor={webSettingData?.buttoncolor}
                        btnbghvcolor={webSettingData?.buttoncolorhover}
                        btntextcolor={webSettingData?.buttontextcolor}
                      >
                        {
                          languageData?.journey_page_booking_btn[
                            webSettingData?.language
                          ]
                        }
                      </BookingBtn>
                    </ButtonWrapper>
                  </Content>
                </ImageContainer>
              </Card>
            ))}
          </Container>
        </>
      )}
    </>
  );
}
