import React, { useEffect, useState } from "react";
import Layout from "../../bootstrap/Layout";
import { useSelector } from "react-redux";
import {
  Date,
  Depature,
  HeroComponent,
  HeroWrapper,
  InnerContainer,
  Location,
  LocationAndDate,
  SearchFormWrapper,
  TripHeader,
  TripHeaderLeft,
} from "../Booking/Booking.styles";
import calendarIcon from "../../../src/assets/images/calender.svg";

import SearchFrom from "../../components/Ui/SearchFrom";
import { toUpperFirst } from "../../helpers";
import Container from "../../bootstrap/Container";
import { HeroArea } from "../Home/Home.styles";
import Hero from "../../components/Ui/Hero";
import {
  BlogImage,
  BlogPageHeaderWrapper,
  CalendarImage,
  CalendarWrapper,
  SingleBlogHeader,
} from "../SingleBlog/SingleBlog.styles";
import styled from "styled-components";
import Blog from "../../components/Ui/Blog";

export const TripImage = styled.img`
  width: 100%;
  max-height: 400px;
  height: 100%;
  margin: 0 auto;
  display: block;
  object-fit: cover;
  border-radius: 5px;
`;

export default function SearchAgain() {
  const locationRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  const [filterBus, setFilterBus] = useState();
  const [singleTrip, setSingleTrip] = useState();
  const { webSettingData, languageData, searchInfoStore } = useSelector(
    (state) => state.busLists
  );
  const [location, setLocation] = useState([]);
  const [heroData, setHeroData] = useState([]);

  const [searchInfo, setSearchInfo] = useState({
    pick_location: "",
    drop_location: "",
    journeydate: "",
  });

  useEffect(() => {
    setSearchInfo(JSON.parse(localStorage.getItem("searchInfo")));
    setSingleTrip(JSON.parse(localStorage.getItem("singleTrip")));
  }, []);
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_MODULE_DOMAIN}/passangers/info`;

    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token");

      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });

          const result = await response.json();
          setLoading(false);

          if (result?.status === "success") {
            localStorage.setItem(
              "userProfileInfo",
              JSON.stringify(result?.data)
            );
            setUserProfileInfo(result?.data);
          } else if (result?.status === "fail") {
            localStorage.removeItem("userProfileInfo");
          }
        } catch (error) {
          setLoading(false);
          localStorage.removeItem("userProfileInfo");
        }
      };

      fetchData();
    }
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/hero`)
      .then((res) => res.json())
      .then((data) => {
        setHeroData(data.data[0]);
      });

    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/locations`)
      .then((res) => res.json())
      .then((data) => {
        setLocation(data.data);
      });
  }, []);
  const getLocationName = (id) => {
    const locationName = location.find((item) => item.id === id);
    return toUpperFirst(locationName?.name);
  };
 

  return (
    <Layout
      title={languageData?.home_tab_title[webSettingData?.language]}
      userProfileInfo={userProfileInfo}
      isLoading={loading}
    >
      <BlogPageHeaderWrapper>
        <h2>
          {languageData?.search
            ? languageData?.search[webSettingData?.language]
            : "search"}
        </h2>
        {/* <p>
          {languageData?.home
            ? languageData?.home[webSettingData?.language]
            : "home"}{" "}
          -{" "}
          {languageData?.blog_details
            ? languageData?.blog_details[webSettingData?.language]
            : "blog_details"}{" "}
        </p> */}
      </BlogPageHeaderWrapper>
      <div
        style={{
          height: "100vh",
        }}
      >
        <HeroArea>
          <HeroComponent img={singleTrip?.imglocation} />

          <>
            <SearchFormWrapper>
              <SearchFrom searchInfo={searchInfo} />
            </SearchFormWrapper>
          </>
        </HeroArea>
        {/* <div
          style={{
            marginTop: "50px",
          }}
        >
          <Container>
            <TripImage src={singleTrip?.imglocation} alt="blogImage" />
            <CalendarWrapper>
              <CalendarImage src={calendarIcon} alt="calendarIcon" />
              <Date>{singleTrip?.created_at}</Date>
            </CalendarWrapper>
            <SingleBlogHeader>{singleTrip?.title}</SingleBlogHeader>
          
          </Container>
        </div> */}
        <Blog />
      </div>
    </Layout>
  );
}
