"use client";

import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../../bootstrap/Layout";
import Container from "../../bootstrap/Container";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const FormContainer = styled.form`
  margin: 100px auto;
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 8px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  column-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  outline: none;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Select = styled.select`
  width: 100%;
  padding: 13px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  outline: none;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #45a049;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
  margin-top: 5px;
  display: block;
`;

export default function RentForm() {
  const { webSettingData, languageData } = useSelector(
    (state) => state.busLists
  );

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    pick_up_date: "",
    drop_off_date: "",
    pick_up_location: "",
    drop_up_location: "",
    passenger: "",
    duration: "",
    request: "",
    intended: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "phone",
      "email",
      "pick_up_date",
      "drop_off_date",
      "pick_up_location",
      "drop_up_location",
      "passenger",
      "duration",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = languageData?.this_field_is_required
          ? languageData?.this_field_is_required[webSettingData?.language]
          : "this_field_is_required";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log(formData);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_MODULE_DOMAIN}/rental/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const result = await response.json();

        if (result.status === "success") {
          toast.success(
            languageData?.rental_create_success_text
              ? languageData?.rental_create_success_text[
                  webSettingData?.language
                ]
              : "rental_create_success_text"
          );
          setFormData({
            name: "",
            phone: "",
            email: "",
            pick_up_date: "",
            drop_off_date: "",
            pick_up_location: "",
            drop_up_location: "",
            passenger: "",
            duration: "",
            request: "",
            intended: "",
          });
        } else {
          toast.error(result.message);
          setFormData({
            name: "",
            phone: "",
            email: "",
            pick_up_date: "",
            drop_off_date: "",
            pick_up_location: "",
            drop_up_location: "",
            passenger: "",
            duration: "",
            request: "",
            intended: "",
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Layout>
      <Container>
        <FormContainer onSubmit={handleSubmit}>
          <h2>
            {" "}
            {languageData?.rental_form
              ? languageData?.rental_form[webSettingData?.language]
              : "rental_form"}
          </h2>
          <h2>
            {languageData?.customer_details
              ? languageData?.customer_details[webSettingData?.language]
              : "customer_details"}{" "}
            :
          </h2>
          <Grid>
            <FormGroup>
              <Label htmlFor="name">
                {" "}
                {languageData?.full_name
                  ? languageData?.full_name[webSettingData?.language]
                  : "full_name"}
              </Label>
              <Input
                type="text"
                placeholder={
                  languageData?.full_name
                    ? languageData?.full_name[webSettingData?.language]
                    : "full_name"
                }
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="phone">
                {languageData?.phone_number
                  ? languageData?.phone_number[webSettingData?.language]
                  : "phone_number"}
              </Label>
              <Input
                type="tel"
                id="phone"
                placeholder={
                  languageData?.phone_number
                    ? languageData?.phone_number[webSettingData?.language]
                    : "phone_number"
                }
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">
                {languageData?.email_address
                  ? languageData?.email_address[webSettingData?.language]
                  : "email_address"}
              </Label>
              <Input
                type="email"
                placeholder={
                  languageData?.email_address
                    ? languageData?.email_address[webSettingData?.language]
                    : "email_address"
                }
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </FormGroup>
          </Grid>
          <h2>
            {" "}
            {languageData?.rental_details
              ? languageData?.rental_details[webSettingData?.language]
              : "rental_details"}{" "}
            :
          </h2>

          <Grid>
            <FormGroup>
              <Label htmlFor="pick_up_date">
                {languageData?.pickup_date
                  ? languageData?.pickup_date[webSettingData?.language]
                  : "pickup_date"}
              </Label>
              <Input
                type="date"
                id="pick_up_date"
                name="pick_up_date"
                value={formData.pick_up_date}
                onChange={handleChange}
                minDate={moment().toDate()}
              />
              {errors.pick_up_date && (
                <ErrorMessage>{errors.pick_up_date}</ErrorMessage>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="drop_off_date">
                {languageData?.dropoff_date
                  ? languageData?.dropoff_date[webSettingData?.language]
                  : "dropoff_date"}
              </Label>
              <Input
                type="date"
                id="drop_off_date"
                name="drop_off_date"
                value={formData.drop_off_date}
                onChange={handleChange}
              />
              {errors.drop_off_date && (
                <ErrorMessage>{errors.drop_off_date}</ErrorMessage>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="pick_up_location">
                {" "}
                {languageData?.pickup_location
                  ? languageData?.pickup_location[webSettingData?.language]
                  : "pickup_location"}
              </Label>
              <Input
                type="text"
                placeholder={
                  languageData?.pickup_location
                    ? languageData?.pickup_location[webSettingData?.language]
                    : "pickup_location"
                }
                id="pick_up_location"
                name="pick_up_location"
                value={formData.pick_up_location}
                onChange={handleChange}
              />
              {errors.pick_up_location && (
                <ErrorMessage>{errors.pick_up_location}</ErrorMessage>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="drop_up_location">
                {languageData?.dropoff_location
                  ? languageData?.dropoff_location[webSettingData?.language]
                  : "dropoff_location"}
              </Label>
              <Input
                type="text"
                placeholder={
                  languageData?.dropoff_location
                    ? languageData?.dropoff_location[webSettingData?.language]
                    : "dropoff_location"
                }
                id="drop_up_location"
                name="drop_up_location"
                value={formData.drop_up_location}
                onChange={handleChange}
              />
              {errors.drop_up_location && (
                <ErrorMessage>{errors.drop_up_location}</ErrorMessage>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="passenger">
                {languageData?.number_of_passenger
                  ? languageData?.number_of_passenger[webSettingData?.language]
                  : "number_of_passenger"}
              </Label>
              <Input
                type="number"
                id="passenger"
                placeholder={
                  languageData?.number_of_passenger
                    ? languageData?.number_of_passenger[
                        webSettingData?.language
                      ]
                    : "number_of_passenger"
                }
                name="passenger"
                value={formData.passenger}
                onChange={handleChange}
              />
              {errors.passenger && (
                <ErrorMessage>{errors.passenger}</ErrorMessage>
              )}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="duration">
                {languageData?.rental_duration
                  ? languageData?.rental_duration[webSettingData?.language]
                  : "rental_duration"}{" "}
                (
                {languageData?.in_hour
                  ? languageData?.in_hour[webSettingData?.language]
                  : "in_hour"}
                )
              </Label>
              <Input
                type="number"
                step="any"
                min={0}
                id="duration"
                placeholder={
                  languageData?.rental_duration
                    ? languageData?.rental_duration[webSettingData?.language]
                    : "rental_duration"
                }
                name="duration"
                value={formData.duration}
                onChange={handleChange}
              />
              {errors.duration && (
                <ErrorMessage>{errors.duration}</ErrorMessage>
              )}
            </FormGroup>
          </Grid>

          <h2>
            {" "}
            {languageData?.additional_requirements
              ? languageData?.additional_requirements[webSettingData?.language]
              : "additional_requirements"}
            :
          </h2>
          <FormGroup>
            <Label htmlFor="request">
              {" "}
              {languageData?.special_requests
                ? languageData?.special_requests[webSettingData?.language]
                : "special_requests"}
            </Label>
            <TextArea
              id="request"
              placeholder={
                languageData?.special_requests
                  ? languageData?.special_requests[webSettingData?.language]
                  : "special_requests"
              }
              name="request"
              value={formData.request}
              onChange={handleChange}
              rows={3}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="intended">
              {" "}
              {languageData?.intended_use
                ? languageData?.intended_use[webSettingData?.language]
                : "intended_use"}
            </Label>
            <TextArea
              id="intended"
              placeholder={
                languageData?.intended_use
                  ? languageData?.intended_use[webSettingData?.language]
                  : "intended_use"
              }
              name="intended"
              value={formData.intended}
              onChange={handleChange}
              rows={3}
            />
          </FormGroup>
          <SubmitButton type="submit">
            {languageData?.submit
              ? languageData?.submit[webSettingData?.language]
              : "submit"}
          </SubmitButton>
        </FormContainer>
      </Container>
    </Layout>
  );
}
